import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { services } from 'src/components/services/services';

import {
  Box, Container, Card, CardContent, Grid, Divider,
  CardHeader, TableContainer, TableRow, Table, TableBody
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';
import _ from "lodash";
// import { Stack,Button,Modal,TextField } from '@mui/material';
import PendingScreen from '../../pages/loan-status-details/pending';
import DisburedScreen from '../../pages/loan-status-details/disbured';

import ApprovedScreen from '../../pages/loan-status-details/approved';
import BreadcrumbsComponent from 'src/components/Breadcrumbs';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(97, 97, 97)',
  transition: 'none',
  backgroundImage: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  border: '1px solid rgb(227, 242, 253)',
  // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: "none",
  '&:hover': {
    // border: '2px solid rgb(227, 242, 253)',
    // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
  }
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: '#038dd7',
  color: '#fffff',

}))

// const TabelHeaderCell = styled(TableCell)(({ theme }) => ({
//   backgroundColor: '#EDE7F6',
//   color: '#5D35B1'
// }))

const MuiTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none"
  }
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontWeight: '500'
  }
}));


export const LoanDetails = () => {
  let { id } = useParams();
  const [details, setDetails] = useState({})
  const [loanDetails, setLoanDetails] = useState({})
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const isString = val => typeof val === 'string';

  useEffect(() => {
    
    
    loanList()
  }, [])
  const history = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history('/login')
    }
  }, [])
  const loanList = () => {
    services.getService(`loan/detail/${id}`)
      .then(res => {
        
        // let data = res.data.filter(l => l.id === id);

        // setDetails({
        //   ...details, ..._.get(res, 'data')
        // })

        setDetails({
           ..._.get(res, 'data')
        })

        setLoanDetails({
          ..._.get(res, 'data')
        })
        
        if (_.get(res, 'data.customerId')) {
          services.getService(`customer/details/${_.get(res, 'data.customerId')}`)
            .then(res => {
              
              let data1 = res;

              setDetails({
                ...details, ..._.get(res, 'data'), customer_details: data1
              })

              

            })
        }
        services.getService(`loan/ledger/${id}`)
          .then(async res => {
            
            if (_.get(res, 'extraData.balance')) {
              setLoanDetails({ ...loanDetails, loan_amount_due: _.get(res, 'extraData.balance') })
            }


          })
      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })
  }
  const onChangeLoanStatus = (value) => {
    
    services.putService(`loan/change-status/${id}`, { ...value })
      .then(res => {

        
        if (_.get(res, 'status') === true) {
          loanList()
        }

      })
      .catch(err => {
        if (!localStorage.getItem('token')) {
          navigate('/login');
        }
      })

  }
  const breadCrumbsData = [{ name: searchParams.get('type') === "my_loan" ? 'My Loans' : 'Loans', path: searchParams.get('type') === "my_loan" ? '/app/my-loans' : '/app/loans' }, { name: 'Details', path: `/app/loan/details/${id}` }]

  return (
    <>
      <Helmet>
        <title>Loan Details - Thangam Capital </title>
      </Helmet>
      {/* <pre>{JSON.stringify(details)}</pre> */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          details && <Container maxWidth="lg">
            <BreadcrumbsComponent data={breadCrumbsData} />

            <Card>
              {/* <Box sx={{ pt: 3, width: '100%' }}> */}
              <CardContent>

                <Grid container spacing={2}>
                  <h2 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', flex: 1, marginTop: '23px', fontSize: '23px', textDecoration: 'underline', fontWeight: '700', textTransform: 'uppercase' }}>{_.startCase(_.get(loanDetails, 'loan_status'))}</h2>
                  <Grid item md={12} lg={12}>
                    <StyledCard>
                      <StyledCardHeader title="Loan Details" />
                      <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                      <CardContent>
                        <TableContainer style={{ overflowX: 'auto' }}>
                          <Table size="small" aria-label="simple table">
                            <TableBody>
                              {/* {_.get(loanDetails, 'loan_id')} */}
                              {_.get(loanDetails, 'loan_id') && _.map(loanDetails, (value, index) => {
                                // 
                                return <TableRow key={index} hover>
                                  {/* {isString(value) ? value : 'Not a string'} */}
                                  {isString(value) ? <BoldTableCell>{_.startCase(index)}</BoldTableCell>:""}
                                  {isString(value) ? <MuiTableCell>:</MuiTableCell>:""}
                                  {isString(value) ? <MuiTableCell>{value}</MuiTableCell>:""}
                                </TableRow>
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: 23 }} container spacing={2}>
                  <Grid item md={12} lg={12}>
                    <StyledCard>
                      <StyledCardHeader title="Customer Details" />
                      <Divider style={{ borderColor: 'rgb(227, 242, 253)' }} />
                      <CardContent>
                        <TableContainer style={{ overflowX: 'auto' }}>
                          <Table size="small" aria-label="simple table">
                            <TableBody>
                              <TableRow hover>
                                <BoldTableCell>Name</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.firstName')} {' '} {_.get(details, 'customer_details.lastName')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Email</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.email')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Gender</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.gender')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Aadhar Id</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.aadharId')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>DOB</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.dob')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Mobile Number</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.mobileNumber')}</MuiTableCell>
                              </TableRow>
                              <TableRow hover>
                                <BoldTableCell>Active Loans</BoldTableCell>
                                <MuiTableCell>:</MuiTableCell>
                                <MuiTableCell>{_.get(details, 'customer_details.active_loans')}</MuiTableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </StyledCard>
                  </Grid>

                </Grid>
                {_.get(loanDetails, 'loan_status') === 'approved' && <ApprovedScreen onChangeLoanStatus={(v) => onChangeLoanStatus(v)} loan_details={loanDetails} open={open} handleClose={handleClose} handleOpen={handleOpen} />}
              </CardContent>
              {/* </Box> */}
              {_.get(loanDetails, 'loan_status') === 'pending_review' && <PendingScreen onChangeLoanStatus={(v) => onChangeLoanStatus(v)} open={open} handleClose={handleClose} handleOpen={handleOpen} />}

            </Card>
          </Container>

        }
        {_.get(loanDetails, 'loan_status') === 'disbursed' && <DisburedScreen loan_details={details} loan_id={id} />}
      </Box>

    </>
  )
}
