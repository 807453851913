import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  Card,
  Divider,
  CardContent,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Fieldset } from "../shared/Fieldset";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormInputDropdown } from "../shared/FormInputDropdown";
import { FormInputText } from "../shared/FormInputText";
import { useFieldArray, useForm } from "react-hook-form";
import "./style.css";
import { DocumentUploadFile } from "../shared/DocumentUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { services } from "../services/services";
import axios from "axios";
import CommonApplicantDetails from "./common-applicant-details";
function FormRow(props) {
  const {
    control,
    name,
    label,
    rules,
    inputType,
    readonly,
    multiline = false,
  } = props;
  return (
    <React.Fragment>
      <Grid item md={6} xs={10} lg={4}>
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          multiline={multiline}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormInputDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

const DocumentDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setloanInfo,
  loanInfo,
  isNextActive,
  ApplicantName,
  customerDetail,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      documents:loanInfo?.documents?.length>0 ?_.map(loanInfo?.documents,(v)=>({...v,documentUpload:{name:v?.fileLocation}})):[
        { type: "", uniqueNo: "", documentUpload: null, fileLocation: "" },
      ],
    },
    // values:{}
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });
  const watchFields = watch("documents") || [];

  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const [storeDocumentList, setstoreDocumentList] = useState([]);

  useEffect(() => {
    setloanInfo({ ...loanInfo, loanType: "SEL" });
  }, []);

  //Document Upload
  const FormRowUpload = ({
    control,
    name,
    label,
    rules,
    id,
    defaultValue,
    image_Details,
    readonly,
    disabled,
    index,
  }) => {
    const handleUploadChange = (values) => {
      postService(values, index);
    };

    return (
      <React.Fragment>
        <Grid item md={6} xs={10} lg={4}>
          <DocumentUploadFile
            imageChange={handleUploadChange}
            id={id}
            show_upload={image_Details}
            readonly={readonly || false}
            defaultValue={defaultValue}
            newFieldTru={(va) => {
              handleUploadChange(va);
            }}
            name={name}
            label={label}
            control={control}
            rules={rules}
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>
    );
  };
  const handleAddRow = () => {
    append({
      type: "",
      uniqueNo: "",
      documentUpload: null,
      fileLocation: "",
    });
  };
  useEffect(() => {
    if (loanInfo?.loanType) {
      let documentList = _.map(
        globalConfiguration?.documentList[loanInfo?.loanType],
        (value, key) => ({ value: key, label: value })
      );
      if (_.size(documentList) > 0) {
        setstoreDocumentList(documentList);
      }
    }
  }, [loanInfo?.loanType]);

  const filterLoanProduct = (loanType) => {
    let loanTypes = _.map(
      _.get(globalConfiguration, "loanProducts"),
      (value, key) => ({ value: key, label: value })
    );
    let product = _.filter(
      loanTypes,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };
  const onSubmit = (data) => {
   if(data?.documents?.length >0){
    setloanInfo({...loanInfo,
      documents:data?.documents
    })
    handleNext()
   }
  };

  const postService = async (service, index) => {
    let ext = _.split(_.get(service, "name"), ".")[1];
    let customerId = _.get(customerDetail, "customerId");
    let key = getValues(`documents.${index}.type`);
    try {
      let response = await services.postService(`file/upload`, {
        documentType: key,
        documentExtension: ext,
        id: customerId,
      });
      if (response.presignedUrl && response.key) {
        setValue(`documents.${index}.fileLocation`, response.key);
        await postFiles(service, response.presignedUrl);
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
        // setApiError({
        //   severity: "error",
        //   message: `Validation Error :: ${Object.values(errors).flat(2).join(" ")}`,
        //   error: true
        // });
      }
      throw err;
    }
  };

  const postFiles = async (f, url) => {
    const formData = new FormData();
    formData.append("file", f);
    
    axios({
      method: "put",
      url: url,
      data: f,
    })
      .then(async (res) => {
        
      })
      .catch((err) => {});
  };

  const getAvailableDocumentTypes = (currentIndex) => {
    const selectedTypes = watchFields.map((doc) => doc.type);
    return storeDocumentList.filter(
      (type) =>
        !selectedTypes.includes(type.value) ||
        selectedTypes[currentIndex] === type.value
    );
  };

  const isAddButtonDisabled = watchFields.some(
    (row) => !row.type || !row.uniqueNo || !row.documentUpload
  );
  const filterCoApplicant = (id) => {
    if (_.size(_.get(customerDetail, "coApplicants")) > 0) {
      let coApplicants = _.filter(
        _.get(customerDetail, "coApplicants"),
        (value, index) => value?.customerId == id
      );
      return {
        coApplicantName:
          _.size(coApplicants) > 0
            ? _.startCase(
                coApplicants[0].firstName + " " + coApplicants[0].lastName
              )
            : "-",
        coApplicantId: coApplicants[0]?.customerId,
        coApplicantNumber: coApplicants[0]?.mobileNumber,
      };
    } else {
      return null;
    }
  };
  return (
    <Card sx={{
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      // backgroundColor: "#f9f9fc",
      padding: "16px",
    }}>
      <form onSubmit={handleSubmit(onSubmit)}>
      
        {/* <Divider /> */}
        <CardContent>
        <CommonApplicantDetails
          applicantName={ApplicantName}
          applicantNumber={customerDetail?.mobileNumber}
          applicantId={customerDetail?.customerId}
          LoanName={filterLoanProduct(loanInfo?.product)}
          loanCode={loanInfo?.product}
          coApplicant={filterCoApplicant(loanInfo?.coApplicant)}
        />
          <Box sx={{ padding: "10px" }}>
         <Fieldset
            margin="0px"
            marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Documents
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
            <Box sx={{marginLeft:"20px",marginRight:"20px",paddingTop:'20px'}}>
            {fields.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  
                }}
              >
                <Grid container spacing={2} key={item.id} alignItems="center">
                  {/* Document Type Dropdown */}

                  <FormRowRadio
                    name={`documents.${index}.type`}
                    control={control}
                    label="Document Type"
                    options={getAvailableDocumentTypes(index)}
                  />

                  <FormRow
                    control={control}
                    name={`documents.${index}.uniqueNo`}
                    label="Document No"
                  />

                  <FormRowUpload
                    name_title={"Proof"}
                    value_detail={""}
                    type_dropdown="id_proof"
                    name={`documents.${index}.documentUpload`}
                    defaultValue={getValues(
                      `documents.${index}.documentUpload`
                    )}
                    label="Aadhaar"
                    formType="formView"
                    control={control}
                    index={index}
                    disabled={
                      !watchFields[index]?.type ||
                      !watchFields[index]?.uniqueNo
                    }
                  />

                  {/* Remove Button */}
                </Grid>
                {fields.length > 1 && (
                  <IconButton
                    onClick={() => remove(index)}
                    aria-label="delete"
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            </Box>
            <Box display={"flex"} justifyContent={"center"} marginTop={"12px"}>
              <Button
                onClick={handleAddRow}
                disabled={isAddButtonDisabled}
                startIcon={
                  <AddCircleOutlineIcon style={{ fill: "#FFF" }} color="#FFF" />
                }
                variant="contained"
                style={{ textTransform: "none" }}
                color="primary"
              >
                Add Document
              </Button>
            </Box>
          </Fieldset>
         </Box>
        </CardContent>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}>
        <Button variant="contained" onClick={() => handleBack()} sx={{ mt: 2, ml: 1 }} type="submit">
              {"Previous"}
            </Button>
          
            <Button variant="contained" sx={{ mt: 2, ml: 1 }} type="submit">
              {"Next"}
            </Button>
          </Box>
       
      </form>
    </Card>
  );
};

export default DocumentDetails;
