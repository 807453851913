import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
// import FacebookIcon from '../icons/Facebook';
// import GoogleIcon from '../icons/Google';
import { services } from 'src/components/services/services';
// import bcrypt from 'bcryptjs'
import { notification } from 'antd';
import Logo from 'src/components/Logo';
import { ReactComponent as Slider1 } from '../assets/images/slider-1.svg';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Login - Thangam Capital</title>
      </Helmet>
      <Box
        sx={{
          // background: "linear-gradient(to bottom, #FFF 50%, #122777 50%)"
          background: "linear-gradient(to bottom, #FFF 50%, transparent 50%),linear-gradient(to left, #0e133b, #0f1645, #101a50, #111d5b, #112166, #112166, #112166, #112166, #111d5b, #101a50, #0f1645, #0e133b)",
// backgroundBlendMode: "overlay",
          // "#122777"
          
          // 'rgba(18, 39, 119, 0.83)',
          display: 'flex',
          // flexDirection: 'column',
          
          height: '100%',
          alignItems:'center',
          width: '100%',
          justifyContent: 'center'
          
        }}
      >
      <Box sx={{display: 'flex',
          // flexDirection: 'column',
          
          height: '400px',
          alignItems:'center',
          width: '100%',
          justifyContent: 'center'}}>
       <Box sx={{width:"70%",height:"100%",border:"2px solid #fdcb00",boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;",
       backgroundColor:"#FFF",
       borderRadius:"12px",
     
        //  background: "linear-gradient(179deg, #0F1642 0%, #122573 0%, #0E1339 100%)",
        //  backgroundImage: "linear-gradient(to right, #0f1643, #10184b, #101b53, #111d5c, #112064, #112064, #112063, #112063, #111d5a, #111a51, #101748, #0f143f)"
       }}>
       <Box sx={{width:"100%",display:'flex',alignItems:'flex-start'}}>
        <div style={{width:"100%", height:"396px",
         borderRadius:"12px",
            borderTopRightRadius:"0px",
          borderBottomRightRadius:"0px",
           backgroundImage: "linear-gradient(to left, #0e133b, #0f1645, #101a50, #111d5b, #112166, #112166, #112166, #112166, #111d5b, #101a50, #0f1645, #0e133b)",
           display:'flex',
           alignItems:'center',
           justifyContent:'center'
        }} >
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
          {/* <div style={{fontSize:"30px",color:"#FFF",fontWeight:"500",textTransform:"uppercase"}}>Kindly welcome come!</div> */}
          <img
          style={{width:"65%"}}
    alt="Logo"
    src="/static/logo-new.png"
   
    
  />
 
          </div>
          </div>
        <Container maxWidth="sm" sx={{boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          // backgroundColor:"#FFF",
          borderRadius:"12px",
          padding:"26px",
          paddingLeft:"26px !important",
          height:"396px",
          borderTopLeftRadius:"0px",
          borderBottomLeftRadius:"0px"
          
        }}>
        
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              // const salt = bcrypt.genSaltSync(10);
              // values = {
              //   ...values, password: bcrypt.hashSync(values.password, salt)
              // }
              console.log(values);
              services.postService(`user/login`, values)
                .then(res => {
                  console.log('resres', res);
                  localStorage.setItem('token', res.token);
                  if (res.status) {
                    navigate('/app/customers', { replace: true });
                  }
                })
                .catch(err => {
                  console.log('err', err);
                  if (err?.response?.status === 400) {
                    notification.error({
                      message: 'Error',
                      description: 'Invalid username or password',
                      placement: 'topRight'
                    });
                  } else {
                    notification.error({
                      message: 'Error',
                      description: err?.data?.error || 'Invalid username or password',
                      placement: 'topRight'
                    });
                  }
                  if (!localStorage.getItem('token')) {
                    navigate('/login');
                  }
                  if (err?.data?.error) {
                    notification.error({
                      message: 'Error',
                      description: err?.data?.error,
                      placement: 'topRight'
                    })
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} style={{padding:"24px"}}>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  // placeholder='Username'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{fill:"#46434d"}} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{fill:"#46434d"}} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ py: 2,display:'flex',alignItems:'center',justifyContent:'center' }}>
                  <Button
                    // color="primary"
                    // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{backgroundColor:"#31458b",width:"50%"}}
                  >
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
        </Box>
       </Box>
       </Box>
      </Box>
    </>
  );
};

export default Login;