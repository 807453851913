import { Box, Typography } from "@mui/material";


export const Fieldset = ({
  title,
  color = '#4984BF',
  titleSize = '1rem',
  borderWidth = 1,
  borderRadius = 2,
  children,
  margin='20px',
  marginTop='20px',
  padding="16px",
  sx = {},
  ...props
}) => {
  return (
    <Box
      sx={{
        border:`${borderWidth}px solid ${color}`,   // Fieldset border
        borderRadius: '8px',           // Rounded corners
        padding: padding,               // Internal padding
        position: 'relative',          // Relative positioning for label placement
        width: '100%',  
        margin:margin,
        marginTop:  marginTop           // Full width
      }}
    >
      
      <Typography
        sx={{
          position: 'absolute',
          top: '-12px',                 // Position above the border
          left: '16px',                 // Left aligned
          backgroundColor: 'white',     // Background to cover fieldset border
          padding: '0 8px',             // Padding around text
          color: color,
            fontSize: titleSize,                 // Color of the label text
        }}
      >
       {title} 
      </Typography>
   
      {children}
    </Box>
  );
};