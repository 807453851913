import React from 'react';
import { Controller } from "react-hook-form";
import { useState } from 'react';
import _ from 'lodash'
import { useEffect } from 'react';
import { Button } from '@mui/material';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
export const DocumentUploadFile = (props) => {
  const hiddenFileInput = React.useRef(null);
  const [uploads, setUploads] = useState({}); // State to track multiple uploads

  const { name, control, rules, disabled = false, id } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (_.get(props, 'value')) {
      setUploads(prevState => ({
        ...prevState,
        [id]: _.get(props, 'value')
      }));
    }
  }, [id, props.value]);
  const renderWithDots = (text) => {
       const limitedWords = `${text}`.slice(0, 27); // Get the first 50 words
       return limitedWords.toString(' ') + (limitedWords.length > 26 ? '...' : '');
     };
     console.log("Test flow",props?.defaultValue?.name);
     
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange },
          fieldState: { error },
        }) => (
          <div style={props?.defaultValue?.name?{
              display:'flex',
              alignItems:'center'
          }:{}}>
            {props?.defaultValue?.name ? (
              <div className="row" style={{display:'flex',alignItems:'center',cursor:'pointer',marginTop:'7px'}} onClick={handleClick}>
                
                <Button style={{height:'40px'}} variant="outlined"  startIcon={<CloudDoneIcon /> }>
  Uploaded
</Button>
                <div style={{marginRight:'20px',cursor:'pointer'}}>
                  
                  {/* {renderWithDots(props?.defaultValue?.name)} */}
                  </div>
              {/* <CloudDoneIcon /> */}
                {/* <BorderColorSharpIcon /> */}
              </div>
            ) : (
              <Button style={{height:'40px'}} variant="outlined" onClick={handleClick} startIcon={<CloudUploadIcon /> }>
  Upload file
</Button>
            )}
            <input
              ref={hiddenFileInput}
              accept="image/*"
              style={{ display: 'none' }}
              type={'file'}
              name="Select Image"
              disabled={props?.disabled}
              onChange={(event) => {
                if (!disabled) {
                  const file = event.target.files[0];
                  setUploads(prevState => ({
                    ...prevState,
                    [id]: { file, type_name: name, id }
                  }));
                  onChange(file);
                  props.newFieldTru(file);
                }
              }}
            />
          </div>
        )}
        rules={fieldRules}
      />
    </>
  );
};
