const Logo = (props) => (
  <div style={{marginTop:'-45px'}}>
  <img
    alt="Logo"
    src="/static/logo.png"
    {...props}
    style={{height:"100%",width:'100%'}}
    
  />
  {/* <div className="logoText">Thangam Capital</div> */}
  </div>
);

export default Logo;
