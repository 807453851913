// import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import { Link as RouterLink, useNavigate } from 'react-router-dom';


const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear()
    localStorage.removeItem('token');
    navigate('/login', { replace: true });
  }

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar sx={{ backgroundImage: "linear-gradient(to left, #0e133b, #0f1645, #101a50, #111d5b, #112166, #112166, #112166, #112166, #111d5b, #101a50, #0f1645, #0e133b)",}}>
        <RouterLink to="/"  style={{
          height:'64px',
          width:"181px",
          margin:'0px',
          padding:'0px',
        }}>
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Hidden xlDown>
          <IconButton color="inherit" size="large">
            <InputIcon />
          </IconButton>
        </Hidden> */}
       
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden>
          <IconButton color="inherit" size="large" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
