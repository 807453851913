const minimumLength = {
  minLength: {
    value: 1,
    message: "Minimum 3 charcters required"
  }
}
const maximumLength = {
  maxLength: {
    value: 30,
    message: "Maximum 30 characters required"
  }
}
const maxValue = {
  max: {
    value: 50,
    message: "Maximum 50%"
  }
}

export const UserRules = {
  fullName: {
    required: {
      value: true,
      message: "Full Name required"
    },
    ...minimumLength,
    ...maximumLength
  },
  interset_rate: {
    required: {
      value: true,
      message: "Interest Rate required"
    },
    // valueAsNumber:true,

    ...maxValue,
    // max:50,
    // maxLength: {
    //   value:2,
    //   // message: "Maximum 50 characters required"
    // }

  },
  username: {
    required: {
      value: true,
      message: "Username required"
    },
    ...minimumLength,
    ...maximumLength
  },
  email: {
    required: {
      value: true,
      message: "Email required"
    },
    ...minimumLength,
    ...maximumLength,
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Please add a valid email address"
    }
  },
  password: {
    required: {
      value: true,
      message: "Password required"
    },
    ...minimumLength,
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      message: "Password policy violation, minimum 10 characters, 1 Captial letter, 1 special character, 1 number"
    }
  },
  password_type: {
    required: {
      value: false,
      message: "Password required"
    },
    ...minimumLength,
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      message: "Password policy violation, minimum 10 characters, 1 Captial letter, 1 special character, 1 number"
    }
  },
  mobileNumber: {
    required: {
      value: true,
      message: 'Mobile number is required'
    },
    pattern: {
      value: /^\d{10}$/,
      message: 'Mobile Number must contain 10 digits with no comma, no spaces, no punctuation and there will be no + sign'
    }
  },
  role_id: {
    required: {
      value: true,
      message: "Role required"
    }
  },
  status: {
    required: {
      value: true,
      message: "User Status required"
    }
  },
  test: {
    required: {
      value: true,
      message: "Test required"
    }
  }
}

export const CustomerRules = {
  firstName: {
    required: {
      value: true,
      message: "First Name required"
    },
    ...minimumLength,
    ...maximumLength
  },
  lastName: {
    required: {
      value: true,
      message: "Last Name required"
    },
    ...minimumLength,
    ...maximumLength
  },
  email: {
    required: {
      value: false,
      message: "Email required"
    },
    // ...minimumLength,
    // ...maximumLength,
    pattern: {
      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      message: "Please add a valid email address"
    }
  },
  gender: {
    required: {
      value: true,
      message: "Gender required"
    }
  },
  aadharId: {
    required: {
      value: true,
      message: 'Aadhar number is required',
    },
    pattern: {
      value: /^[1-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
      message: "Aadhaar number must have 12 digits & should not contain any alphabet and special characters as per UIDAI "
    }
  },
  dob: {
    required: {
      value: true,
      message: "Date of birth required"
    }
  },
  dateExpected: {
    required: {
      value: false,
      message: "Date required"
    }
  },
  accountNumber: {
    required: {
      value: true,
      message: "Account number required"
    }
  },
  branchName: {
    required: {
      value: true,
      message: "Bank branch required"
    }
  },
  bankName: {
    required: {
      value: true,
      message: "Bank Name required"
    }
  },
  caste: {
    required: {
      value: true,
      message: "Caste required"
    }
  },
  education: {
    required: {
      value: true,
      message: "Education required"
    }
  },
  expenses: {
    required: {
      value: true,
      message: "Expenses required"
    }
  },
  religion: {
    required: {
      value: true,
      message: "Religion required"
    }
  },
  spouseName: {
    required: {
      value: false,
      message: "Religion required"
    }
  },
  residenceType: {
    required: {
      value: true,
      message: "Residence Type required"
    }
  },
  ifscCode: {
    required: {
      value: true,
      message: "IFSC code required"
    }
  },
  maritialStatus: {
    required: {
      value: true,
      message: "Maritial Status required"
    }
  },
  mothersName: {
    required: {
      value: true,
      message: "Mother's name required"
    }
  },
  fathersName: {
    required: {
      value: true,
      message: "Father's name required"
    }
  },
  commonValidation: (name, index) => {
    return {
      value: index && index > 2 ? false : true,
      message: `${name} required`
    }
  },




  contact_no: {
    required: {
      value: true,
      message: "Contact Number required"
    },
    pattern: {
      value: /^\d{10}$/,
      message: 'Contact Number must contain 10 digits with no comma, no spaces, no punctuation and there will be no + sign'
    }
  },
  mobileNumber: {
    required: {
      value: true,
      message: 'Mobile number is required'
    },
    pattern: {
      value: /^\d{10}$/,
      message: 'Mobile Number must contain 10 digits with no comma, no spaces, no punctuation and there will be no + sign'
    }
  },
  occupation: {
    required: {
      value: true,
      message: "Occupation required"
    }
  },
  physicallyChallenged: {
    required: {
      value: true,
      message: "Physically Challenged required"
    }
  },
  guarantor_dob: {
    required: {
      value: true,
      message: "Guarantor DOB required"
    }
  },
  guarantor_fathers_spouseName: {
    required: {
      value: true,
      message: "Guarantor Father's spouse name required"
    }
  },
  guarantor_gender: {
    required: {
      value: true,
      message: "Gender required"
    }
  },
  guarantor_relationship: {
    required: {
      value: true,
      message: "Relationship required"
    }
  },
  permenant_address1: {
    required: {
      value: true,
      message: "Permenant Address1 required"
    }
  },
  permenant_address2: {
    required: {
      value: true,
      message: "Permenant Address2 required"
    }
  },
  permenant_city_village: {
    required: {
      value: true,
      message: "Permenant City/Village required"
    }
  },
  permenant_district: {
    required: {
      value: true,
      message: "Permenant District required"
    }
  },
  permenant_number: {
    required: {
      value: true,
      message: "Permenant Number required"
    }
  },
  permenant_state: {
    required: {
      value: true,
      message: "Permenant State required"
    }
  },
  present_address1: {
    required: {
      value: true,
      message: "Present Address1 required"
    }
  },
  address1: {
    required: {
      value: true,
      message: "Address required"
    }
  },
  present_address2: {
    required: {
      value: true,
      message: "Present Address2 required"
    }
  },
  present_city_village: {
    required: {
      value: true,
      message: "present City/Village required"
    }
  },
  present_district: {
    required: {
      value: true,
      message: "Permenant District required"
    }
  },
  present_number: {
    required: {
      value: true,
      message: "Present Number required"
    }
  },
  present_state: {
    required: {
      value: true,
      message: "Present State required"
    }
  },
  age: {
    required: {
      value: true,
      message: "Age required"
    }
  },
  loan_company: {
    required: {
      value: true,
      message: "Loan Company required"
    }
  },
  loan_amount: {
    required: {
      value: true,
      message: "Loan Amount required"
    }
  },

}


export const LoanRules = {
  loanType: {
    required: {
      value: true,
      message: "Loan product is required"
    }
  },
  coApplicant: {
    required: {
      value: true,
      message: "CoApplicant is required"
    }
  },
  typeOfIndustry: {
    required: {
      value: true,
      message: "Type of industry required"
    }
  },
  customerId: {
    required: {
      value: true,
      message: "Customer is required"
    }
  },
  subCategory: {
    required: {
      value: true,
      message: "Sub category required"
    }
  },
  businesExperience: {
    required: {
      value: true,
      message: "Business experience required"
    }
  },
  tradeName: {
    required: {
      value: true,
      message: "Business name required"
    }
  },
  LocationOfOperation: {
    required: {
      value: true,
      message: "Location of operation required"
    }
  },
  yearsOfOperation: {
    required: {
      value: true,
      message: "Years of operation required"
    }
  },
  totalInvestment: {
    required: {
      value: true,
      message: "Total investment required"
    }
  },
  businessPremises: {
    required: {
      value: true,
      message: "Business premises required"
    }
  },
  noOfWomenEmployee: {
    required: {
      value: true,
      message: "No of women employee required"
    }
  },
  totalEmployee: {
    required: {
      value: true,
      message: "Total Employee required"
    }
  },
  panCardNo: {
    required: {
      value: true,
      message: "Pan Card Number required"
    }
  },
  annualIncome: {
    required: {
      value: true,
      message: "Annual income required"
    }
  },
  licenseNo: {
    required: {
      value: true,
      message: "License number required"
    }
  },
  gstNo: {
    required: {
      value: true,
      message: "GST number required"
    }
  },
  address: {
    required: {
      value: true,
      message: "Address required"
    }
  },
  pinCode: {
    required: {
      value: true,
      message: "Pincode required"
    }
  },
  phoneNo: {
    required: {
      value: true,
      message: "Phone number required"
    },
    pattern: {
      value: /^\d{10}$/,
      message: 'Mobile Number must contain 10 digits with no comma, no spaces, no punctuation and there will be no + sign'
    }
  },
  email: {
    required: {
      value: true,
      message: "Email required",
     
    },
    pattern: {
      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      message: "Please add a valid email address"
    }
  },
  purposeOfLoan: {
    required: {
      value: true,
      message: "Purpose of loan required"
    }
  },
  acres: {
    required: {
      value: true,
      message: "Acres required"
    }
  },
  landValue: {
    required: {
      value: true,
      message: "Land value required"
    }
  },
  location: {
    required: {
      value: true,
      message: "Location required"
    }
  },
  sureveyNo: {
    required: {
      value: true,
      message: "Survey No required"
    }
  },
  subDivision: {
    required: {
      value: true,
      message: "Sub division required"
    }
  },
  typeOfLivestock: {
    required: {
      value: true,
      message: "Type of Livestock required"
    }
  },
  annualSalary: {
    required: {
      value: true,
      message: "Annual salary required"
    }
  },
  ownerShip: {
    required: {
      value: true,
      message: "Owner ship required"
    }
  },
  noOfLivestock: {
    required: {
      value: true,
      message: "No of lives stock required"
    }
  },
  liveStockValue: {
    required: {
      value: true,
      message: "Live stock value required"
    }
  },
  otherIncome: {
    required: {
      value: true,
      message: "Other income required"
    }
  },
  companyCategory: {
    required: {
      value: true,
      message: "Company category required"
    }
  },
  companyName: {
    required: {
      value: true,
      message: "Company name required"
    }
  },
  designation: {
    required: {
      value: true,
      message: "Designation required"
    }
  },
  yearOfExperience: {
    required: {
      value: true,
      message: "Years of experience required"
    }
  },
  loanAmount: {
    required: {
      value: true,
      message: "Loan amount required"
    }
  },
  loanTenure: {
    required: {
      value: true,
      message: "Loan tenure required"
    }
  },

  
  
}