import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  Stack,
  TableContainer,
  createTheme,
  Button
} from '@mui/material';
import getInitials from '../../utils/getInitials';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
import _ from 'lodash'
import { Spin } from 'antd';
import { alpha, getContrastRatio } from '@material-ui/core';
const violetBase = '#7F00FF';
const violetMain = alpha(violetBase, 0.7);
const theme = createTheme({
  palette: {
    violet: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
});
const CustomerListResults = ({ customers = [], onChangePage, pagination_details, loading, ...rest }) => {
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  console.log("pagination_details", pagination_details);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [_id, set_Id] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    onChangePage(page1, event.target.value)
  };
  console.log("customers", customers);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setPage1(page < newPage ? newPage + 10 : newPage - 10)
    onChangePage(page < newPage ? newPage : newPage, limit)
  };
  const handleDeleteCustomer = (customerId) => {
    setOpen(true);
    setCustomerId(customerId);
  }
  
  const onConfirm = () => {
    setOpen(false);
    services.putService(`customer/delete/${customerId}`)
      .then(res => {
        setOpenAlert(true);
      })
      .catch(err => err);
  }

  return (
    <>
      <Box >
        <TableContainer style={{ maxHeight: "550px", overflowX: 'auto' }}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                Customer Id
                </TableCell>
                
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  DOB
                </TableCell>
                <TableCell>
                  Total Loans
                </TableCell>
                <TableCell>
                  Active Loans
                </TableCell>
                <TableCell >
                  Loan Actions
                </TableCell>
                <TableCell >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(0, limit).map((customer) => (
                <TableRow
                  hover
                  key={customer._id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={customer.avatarUrl}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(customer.firstName)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {`${customer.firstName} ${customer.lastName}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer?.customerId}
                  </TableCell>
                  {/* <TableCell>
                    {customer.email}
                  </TableCell> */}
                  <TableCell>
                    {customer.mobileNumber}
                  </TableCell>
                  <TableCell>
                    {moment(customer.dob).format('DD/MM/YYYY')}
                    {/* {customer.dob} */}
                  </TableCell>
                  <TableCell>
                    {customer.totalLoans?customer.totalLoans:'--'}
                  </TableCell>
                  <TableCell>
                    {customer.activeLoans?customer.activeLoans:'--'}
                  </TableCell>
                  <TableCell style={{width:'100px'}}>
                    
                  
                    <div style={{display:'flex',flexDirection:'column'}}>
                    <Button disableRipple disableFocusRipple href={`customers/loan/add/${customer._id}`} variant="outlined"  
                    startIcon={<AddCircleTwoToneIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",
                    marginRight:'5px',marginBottom:'5px'}}>
                    Apply</Button>
                    <Button disableRipple disableFocusRipple href={`customers/details/${customer._id}?tab=loan`} variant="outlined" 
                     startIcon={<PreviewIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",
                    marginRight:'5px',marginBottom:'5px'}}>
                    View</Button>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: '150px' }}>
               
                    <Button disableRipple disableFocusRipple href={`customers/${customer._id}`} variant="outlined"  startIcon={<PreviewIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",
                    marginRight:'5px',marginBottom:'5px'}}>
                    View</Button>
                    <Button disableRipple disableFocusRipple href={`customers/${customer._id}`} variant="outlined"  startIcon={<EditIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",marginRight:'5px',marginBottom:'5px'}}>
                    Edit</Button>
                    {/* <Button disableRipple disableFocusRipple href={`customers/${customer._id}`} variant="outlined"  startIcon={<VisibilityIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",marginRight:'5px',marginBottom:'5px'}}>
                    Details</Button> */}
                    {/* <Button disableRipple disableFocusRipple onClick={() => handleDeleteCustomer(customer._id)} variant="outlined"  startIcon={<DeleteIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",marginRight:'5px',marginBottom:'5px'}}>
                    Delete</Button> */}
                      {/* <Chip label="Edit" size="small" component="a" href={`customers/${customer._id}`} clickable icon={<EditIcon />} color="secondary" />
                      <Chip label="Details" size="small" component="a" href={`customers/details/${customer._id}`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="Delete" size="small" onClick={() => handleDeleteCustomer(customer._id)} icon={<DeleteIcon />} color="secondary" /> */}
       
                  </TableCell>
                </TableRow>
              ))}
              {loading === false && customers.length === 0 && <TableRow style={{ height: '250px' }} > <TableCell colSpan={9} style={{ alignItems: 'center', textAlign: 'center', marginTop: '50px', marginBottom: "50px" }}>No Records Found</TableCell> </TableRow>}

              {loading === true && <TableRow style={{ height: '250px' }} > <TableCell colSpan={9} style={{ alignItems: 'center', textAlign: 'center', marginTop: '50px', marginBottom: "50px" }}> <Spin size="large" /></TableCell> </TableRow>}

            </TableBody>
          </Table></TableContainer>
      </Box>
      <TablePagination
      align='center'
      sx={{alignItems:'flex-end',
        justifyContent:'flex-end',
        display:'flex'
      }}
        // component="div"
        count={_.get(pagination_details, 'total', 10)}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <DialogBox open={open} setOpen={setOpen} onConfirm={onConfirm} text="Delete the Customer, Are you sure?" />
    </>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
